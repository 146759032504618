import React, { Component } from 'react';
import {Link} from 'react-router-dom';

// comp
import Desktop from './Desktop';
import Mobile from './Mobile';

class Navbar extends Component {
    state = {
    	is_open: false,
        links : [
            {
                route: '/',
                text: 'Home'
            },
            {
                route: '/about',
                text: 'About'
            },
            {
                route: '/schedule',
                text: 'Schedule'
            },
            {
                route: '/teams',
                text: 'Team'
            }
        ]
    }

    renderNavbar = () => {
        let list;
        let pathname = window.location.pathname;
        const {actionsMain} = this.props;
        list = this.state.links.map((data, index) =>
            <li key={index} className={`${pathname === data.route ? `active`: ``}`}>
                <Link to={data.route} onClick={()=>actionsMain.toggle_nav()}>{data.text}</Link>
            </li>
        )
        return list;
    }

    render() {
        const {login} = this.props.auth;
    	
        return (
            <div className="navbar">
                <Desktop {...this.props} login={login}renderNavbar={this.renderNavbar} />
                <Mobile {...this.props} login={login} renderNavbar={this.renderNavbar} />
            </div>
        );
    }
}

export default Navbar;