import axios from 'axios';
import {getUrlParam,removeParamUrl} from 'helper';
import {toggle_loader,catch_error} from './main'
import {get_schedule,get_wave} from './regis'

const ls = window.localStorage;
const err = {
    1:'Missing user data',
    2:'Error GOP',
    3:'Kamu belum memiliki akun AOV',
    4:'Missing AOV account',
    m:'Website is under maintenance'
}

const get_login = (data) => ({
    type: 'GET_LOGIN',
    data
})

export const set_token = () =>{
    return dispatch => {
        var token = getUrlParam('token');
        dispatch(toggle_loader(true)); 
        if(getUrlParam('err')){
            dispatch(catch_error(err[getUrlParam('err')]))
            removeParamUrl()
        }else{        
            if(token) {
                ls.setItem('token', token);
                window.location.href = `${window.location.origin}/regis`;
            }else{
                dispatch(check_login())
            }
        }  
        dispatch(get_schedule())    
    }
}

export const check_login = () => {
    return dispatch => {
        dispatch(toggle_loader(true))
        axios.get('oauth/check_login/')
        .then((resp) => {
            dispatch(get_login(resp.data.user))
            dispatch(get_wave())
        })
        .catch((err) => {
            let pathname = window.location.pathname;
            if(err.response){
                // dispatch(catch_error(err.response.data.msg))
            }else{
                dispatch(catch_error())
            }
            if(pathname.indexOf('/regis') > -1){
                window.location.href = '/'
            }
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

export const logout = () =>{
    return (dispatch,getState) =>{
        const {login} = getState().auth;
        if(!login){
            window.location.href = `${process.env.REACT_APP_API_ENDPOINT}oauth/login/`;    
        }else{   
            let token = window.localStorage.getItem('token');
            window.localStorage.clear();
            window.location.href = `${process.env.REACT_APP_API_ENDPOINT}oauth/logout/?token=${token}`;
        }
    }
}
