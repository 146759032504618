import React, { Component } from 'react';

class Standing extends Component {
	versioning = () => {
		return Math.round(Math.random() * 100000000);
	}

	render() {
		return (
			<>
				<center>
					<h1>
						Team
					</h1>
				</center>
			</>
		);
	}
}

export default Standing;
