import React, { Component } from 'react';
import {Link} from 'react-router-dom';
const api = process.env.REACT_APP_API_ENDPOINT;

class Section1 extends Component {
    render() {
        const {login} = this.props.auth;
        const {data} = this.props.main;
        const {actionsMain} = this.props;

        return (
        	<div className="section1">
        		<div className="section1_content">
        			<div className="big_logo">
        				<img src={data?.img_logo ? data?.img_logo : require('assets/img/Icon_Logo_ASL_.png')} alt="" className="img-fluid"/>
        			</div>
        			<div className="text">
        				<img src={data?.img_text_headline ? data?.img_text_headline : require('assets/img/Text.png')} alt="" className="img-big"/>
        			</div>
                    {login ?
            			<Link to="/regis" className="btn_regis" onClick={()=>actionsMain.toggle_nav()}>
        				<img src={require('assets/img/Btn_CTA_Register.png')} alt="" className="img-regis"/>
            			</Link>
                        :
                        <a href={`${api}oauth/login`} className="btn_regis" onClick={()=>actionsMain.toggle_nav()}>
        				<img src={require('assets/img/Btn_CTA_Register.png')} alt="" className="img-regis"/>
                        </a>
                    }

							{ data?.sponsor?.length ? (
								<div className="sponsor">
									<span>Powered by:</span>
									<img src={data.sponsor[0]?.img} alt=""  />
								</div>
							) : null}

        			<img src={require('assets/img/Icon_Under_Arrow.png')} alt="" className="scroll_to"/>
            		<div className="sponsor_list">
            			<p>Presented By</p>
            			<div className="main">
                        {data && data.main_sponsor ?
            				'Main Sponsor':''
                        }
            			</div>
            			<div className="sponsor">
                            {data && data.sponsor?.length > 0 ?
                                (data.sponsor.map((init,x)=>
                                    <div key={x}>{init.name}</div>
                                ))
                                :
                                ''
                            }
            			</div>
            		</div>
        		</div>
        	</div>
        );
    }
}

export default Section1;
