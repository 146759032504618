import React, { Component } from 'react';
// import {dates} from 'helper';

class News extends Component {
	render(){
		// const {data} = this.props.main;
		return(
			<React.Fragment>
			</React.Fragment>
		);
	}
}

export default News;
