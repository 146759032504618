import React, { Component } from 'react';

class Rules extends Component {

	handleopen = (e) =>{
		const {actionsMain} = this.props;
		if (window.innerWidth > 900) {
			e.preventDefault();
			actionsMain.toggle_popup(true,'rules')
		}
	}

	render(){
		const {data} = this.props.main;

		return(
			<div className="rules_container">
				<div className="rules_content">
					<div className="title">
						Rules
						<p>Download dan baca rules</p>
					</div>
					<a href={data.link_rules} className="btn_rules" target="blank" onClick={(e)=>this.handleopen(e)}>
						Download Rules ASL
					</a>
				</div>
			</div>
		);
	}
}

export default Rules;
