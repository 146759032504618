const main_state = {
	nav : false,
	tab : 'qualifier',
	login : false,
	loader: false,
	lang_json: false,
	err_msg: 'Please refresh your browser!',
	popup_is_open: false,
	popup_reg_is_open: false,
	popup_reg_key: false,
	popup_key: false,
	data: false,
	about: 'bracket'
}

const main = (state = main_state, action) => {
    switch (action.type) {
		case "PUT_DATA":
			return { 
				...state,
				[action.key]: action.data 
			};
		case "TOGGLE_LOADER":
			return { 
				...state,
				loader: action.data
			}
		case "TOGGLE_NAV":
			return { 
				...state,
				nav: !state.nav
			}
		case "CHANGE_TAB":
			return {
				...state,
				tab: action.data
			}
		case "TOGGLE_POPUP":
			return {
				...state,
				popup_is_open: action.data,
				popup_key: (action.key)? action.key:false,
			}
		case "TOGGLE_POPUP_REG":
			return {
				...state,
				popup_reg_is_open: action.data,
				popup_reg_key: (action.key) ? action.key: false
			}
		default:
			return state;
	}
};

export default main;