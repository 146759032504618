import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Slider from "react-slick";
import {dateFormat} from 'helper';

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { checkDate } from '../../../helper';

class Schedule extends Component {
	render(){
		const {actionsMain} = this.props;
        const {schedule} = this.props.regis;

		var settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1
		};

		return(
			<React.Fragment>
				<div className="title">
					Schedule
					<Link to="/schedule" onClick={()=>actionsMain.toggle_nav()}>Check all</Link>
				</div>


				{/* YOU CAN REMOVE OR REFACTOR THIS CODE IF NECESSARY LATER 
				<Link to="/schedule" onClick={()=>actionsMain.toggle_nav()} className="default_schedule">
					<img src="https://web.cdn.id.garenanow.com/webid/AOV/ASL%20S4/ASL%20Playoff/schedule_home.png" alt=""/>
				</Link>
					*/}


				{schedule && schedule.length > 0 ?
					<Slider {...settings}>
						{(schedule.map((data,x)=>
							<div key={x} className={`schedule_list`}>
								{data.bracket.map((init,y)=>
									<div key={y} className={`list ${checkDate(data.bracket,y)}`}>
										<div className="date">
											{dateFormat(init,true)}
										</div>
										<div className="list_box">
											<p>{`${data.name} Bracket ${y+1}`}</p>
											<a href={data.bracket_url[y]} className="btn_bracket">
												See Bracket
											</a>
										</div>
									</div>
								)}
							</div>
						))}
					</Slider>
					:
					''
				}
			</React.Fragment>
		);
	}
}

export default Schedule;
