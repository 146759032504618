import React, { Component } from 'react';

class Footer extends Component {
    render() {
				const { data } = this.props.main;

        return (
            <div className="footer">
            	<div className="footer_content">
								{/* <div style={{ display: "inline-block", marginRight: "95px" }}>&nbsp;</div>  */}
								{/* <span style={{ float: "right" }}>SUPPORTED</span> */}
								{data?.sponsor?.length ? (
									<>
										<span>POWERED BY</span> 
										<ul>
											{data.sponsor.map((sponsor) => (
												<li key={sponsor.id}>
													<a href={sponsor.link} target="_blank" rel="noopener noreferrer">
														<img src={sponsor.img} alt="" className="img-fluid"/>
													</a>
												</li>
											))}
										</ul>
									</>
								) : null
								}
            		<div className="copyright">
            			Copyright © Garena Online. Trademarks belong to their respective owners. All Rights Reserved Copyright Ⓒ Tencent. All rights reserved.
            		</div>
            	</div>
            </div>
        );
    }
}

export default Footer;
