import React, { Component } from 'react';
import {Link} from 'react-router-dom';

const tab_title = ['qualifier','playoff','league','final'];
class About extends Component {

	toggle_about = () =>{
		const {about} = this.props.main;
		let data = 'reward';
		if(about === 'reward'){
			data = 'bracket';
		}
		this.props.actionsMain.put_data('about',data);
	}

	render(){
		const {actionsMain} = this.props;
		const {tab,data,about} = this.props.main;

		return(
			<React.Fragment>
				<div className="title">
					About {data?.title_is_playoff}
					<Link to="/about" onClick={()=>actionsMain.toggle_nav()}>Check all</Link>
				</div>
				<div className="about_tab">
					<div className="tab_title">
						{tab_title.map((data,x)=>
							<div key={x} className={`tab-item ${data === tab ? `active`:``}`} onClick={()=>actionsMain.change_tab(data)}>
				                {data === 'qualifier' ? <span>Qualifier</span> : ''}
				                {data === 'playoff' ? <span>Playins</span> : ''}
						{data === 'league' ? <span>Regular Season</span> : ''}
						{data === 'final' ? <span>Grand Final</span> : ''}
				            </div>
						)}
					</div>
					<div className="tab_content">
						{tab_title.map((init,x)=>
							<div key={x} className={`${init === tab ? `active`:``}`}>
				                <div className="qualifier">
				                	<div className="text_title mobile">
		                			{data && data[`${init}_header`] ?
			                			data[`${init}_header`]:''
		                			}
			                		</div>
			                		<div className="img_bracket">
			                			<div className="toggle_bracket">
			                				<div className="arrow left" onClick={()=>this.toggle_about()}>
			                					<img src={require('assets/img/scroll.png')} alt="" className="img-fluid" />
			                				</div>
			                			{about === 'reward' ?
			                				<p>Reward</p>:
									<p>{about}</p>
			                			}
			                				<div className="arrow right" onClick={()=>this.toggle_about()}>
			                					<img src={require('assets/img/scroll.png')} alt="" className="img-fluid" />
			                				</div>
			                			</div>
			                			{about === 'reward' ?
			                				<img src={data[`${init}_reward_img`]} alt="" className="img-fluid img"/>
						                	:
			                				<img src={data[`${init}_img`]} alt="" className="img-fluid img"/>
			                			}
			                		</div>
				                	<div className="text">
				                		<div className="text_title">
			                			{data && data[`${init}_header`] ?
				                			data[`${init}_header`]:''
			                			}
				                		</div>
				                		<div className="text_content">
				                			<p>
			                				{data && data[`${init}_content`] ?
					                			data[`${init}_content`]:''
				                			}
				                			</p>
				                		</div>
				                	</div>
				                </div>

				            </div>
						)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default About;
