import React, { Component } from 'react';
import {dates} from 'helper';
import { sendGAPageView } from '../../../helper';

class Wave extends Component {
	
	componentDidMount (){
		sendGAPageView();
    }

	handleClick = (id,is_regis) =>{
		const {actionsRegis,actionsMain,history,regis} = this.props;

		actionsMain.toggle_nav();
		if (is_regis) {
			actionsRegis.get_data_by_id(id,history);
		} else {
			if (regis.wave.filter(w => w.is_registered === true).map(w => w.is_registered).length >= 1) {
				actionsMain.toggle_popup_reg(true, id);
			} else {
				actionsRegis.reset_form();
				actionsRegis.put_data('err_dict',false);
				history.push(`/regis/${id}`);
			}
		}
	}

	mapBtn = (id,is_registered,is_editable) =>{
		let list = (
			<div 
				className="btn_daftar" 
				onClick={
					is_editable ?
					()=>this.handleClick(id,is_registered)
					: null
				}
			>
				{is_editable ? 
					(is_registered ?
						`Edit` : `Daftar`
					)
					:
					'Tutup'
				}
			</div>
		)
		return list;
	}

	mapWave = (wave) =>{
		return wave.map((data,x)=>
			<div key={x} className="wave_box">
				<div className="text">
					{data.name}
					<p>
						<span>Tanggal Turnamen </span>
						<span>:</span>  
						<span>
							{dates(data.date_start,data.date_end)}
						</span>
					</p>
					<p>
						<span>Tutup Registrasi </span>
						<span>:</span>
						<span>{dates(data.wave_closed)}</span>
					</p>
				</div>
				<div className="btns">
					{data.is_registered ?
						<div className="label">Registered</div>:''
					}
					{this.mapBtn(data.id,data.is_registered,data.is_editable)}
				</div>
			</div>
		)
	}

	render(){
		const {wave} = this.props.regis;
		return(
			<div className="wave">
				<div className="regis_title">
					Pilih Wave Anda
				</div>
				<div className="note">Setiap tim bisa mendaftar di semua wave dan data pendaftaran bisa diganti oleh kapten (orang yang mendaftar) sebelum pendaftaran ditutup. Jika ada pertanyaan lebih lanjut, silahkan hubungi admin AOV (WA : +62 812-1290-8243)</div>
				<div className="wave_list">
					{wave && wave.length > 0 ?
						this.mapWave(wave)
						:
						''
					}
				</div>
			</div>
		);
	}
}

export default Wave;
