import React, { Component } from 'react';

class Video extends Component {
	render(){
		const {data} = this.props.main;
		return(
			<div className="video">
				<img src={require('assets/img/Ornate_Video.png')} alt="" className="img-fluid bg"/>
				<div className="video_box">
					<iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${data && data.videoid_url ?  data.videoid_url : `3bhtuamDXRA`}`} frameBorder="0" allow="autoplay; encrypted-media" allowFullScreen title="yt"></iframe>
				</div>
			</div>
		);
	}
}

export default Video;