import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsMain from 'redux/actions/main';
import { sendGAPageView } from '../../helper';

// comp 
import Section1 from './comp/Section1'
import Video from './comp/Video'
import About from './comp/About'
import Rules from './comp/Rules'
import Schedule from './comp/Schedule'
import Standing from './comp/Standing'
import Teams from './comp/Teams'
import News from './comp/News'

const comp = {
	about: About,
	rules: Rules,
	schedule: Schedule,
	standing: Standing,
	teams: Teams,
	teams2: Teams,
	news: News
}

class Home extends Component {

	componentDidMount (){
		sendGAPageView();
    }
	
	renderComp = (key) =>{
		const Handler = comp[key] || About;
		
		if (key.indexOf('teams') > -1) {
			return <Handler {...this.props} playoff={key==='teams' ? false : true} />
		}

		return 	<Handler { ...this.props } />
	}

	mapComp = () =>{
		let list = [];
		for (var key in comp) {
		    if (comp.hasOwnProperty(key)) {
		    	list.push(
		    		<div key={key} className="detail">
            			<div className={`detail_container ${key}`}>
            				{this.renderComp(key)}
            			</div>
            		</div>
		    	)
		    }
		}
		return list;
	}

    render() {
        return (
            <div className="home">
            	<Section1 {...this.props}/>
            	<Video {...this.props}/>
            	<div className="home_content">
			    	{this.mapComp()}
            	</div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        main: state.main,
        auth: state.auth,
        regis: state.regis
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(actionsMain, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);
