const bracket = () =>{
	var data={};
	for(var i=1;i<=4;i++){
		data[`bracket${i}`] = 0
		data[`bracket${i}`] = 0
		data[`bracket${i}`] = 0
	}
	return data;
}

const team = () =>{
	var data={};
	for(var i=1;i<=6;i++){
		if(i !== 6){
			data[`member${i}_phone`] = ''
		}
		data[`member${i}_name`] = ''
		data[`member${i}_nick`] = ''
		data[`member${i}_open_id`] = ''
	}
	return data;
}

export const form = {
	team_name: '',
	team_email: '',
	team_city: '',
	wave_id: '',
	...team(),
	...bracket()
}

const regis_state = {
	login: false,
	wave: [],
	err_dict: false,
	schedule: [],
	...form
}

const regis = (state = regis_state, action) => {
    switch (action.type) {
    	case "PUT_DATA":
			return { 
				...state,
				[action.key]: action.data 
			};
		case "GET_LOGIN":
			return { 
				...state,
				login: action.data
			};
		case "HANDLE_TICK":
			return {
				...state,
				[`bracket${action.id}`] : (state[`bracket${action.id}`] === 0) ? 1 : 0,
			}
		case "GET_DATA_UPDATE":
			return {
				...state,
				...action.data
			}
		case "RESET_FORM":
			return {
				...state,
				...form
			}
		default:
			return state;
	}
};

export default regis;