import ReactGA from "react-ga4"

export const initGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GA);
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
    });    
}

export const sendGAPageView = () => {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
    }); 
}

export const getUrlParam = (pa) => {
    var url = window.location.href.replace(/#+.*$/, ),
        params = url.substring(url.indexOf("?") + 1, url.length).split("&"),
        param = {};

    for (var i = 0; i < params.length; i++) {
        var pos = params[i].indexOf('='),
            key = params[i].substring(0, pos),
            val = params[i].substring(pos + 1);
            
        param[key] = val;
    }

    return (typeof (param[pa]) === "undefined") ? false : param[pa];
}

export const removeParamUrl = () => {
    const location = window.location;
    window.history.pushState({}, document.title, location.origin + location.pathname);
}

export const err_msg = (err) => {
    if (err.response) {
        switch (err.response.status) {
            case 500:
                return "Please Refresh Your Browser!";
            default:
                return err.response.data.msg;
        }
    } else if (err.request) {
        return "Please Refresh Your Browser!";
    } else {
        return "Please Refresh Your Browser!";
    }
}

export const dateFormat = (dates,type) => {
    var date = new Date(dates);
    var month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    var days = ["Senin","Selasa","Rabu","Kamis","Jumat","Sabtu","Minggu"];
    var day = date.getDate();
    var text_month = month[date.getMonth()];
    var text_days = (date.getDay() > 0) ? days[date.getDay()-1] : days[6];
    var year = date.getFullYear();
    var formatdays = '';
    var hours = (date.getHours() === 0) ? '00' : date.getHours();
    var minutes = (date.getMinutes() === 0) ? '00' : date.getMinutes();
    if(text_days){
        formatdays = `${text_days},`;
    }
    var format = `${formatdays} ${day} ${text_month} ${year}`;
    if(type){
        format = `${formatdays} ${day} ${text_month} ${year} | ${hours}:${minutes}`;
    }
    return format;
}

export const dates = (start,end)=>{
    var date_start = new Date(start);
    var date_end = new Date(end);
    var month_abbr = ["Jan","Feb","Mar","Apr","May","June","July","Aug","Sept","Oct","Nov","Dec"];
    let start_day = date_start.getDate();
    let end_day = date_end.getDate();
    let start_month = month_abbr[date_start.getMonth()];
    let end_month = month_abbr[date_end.getMonth()];
    let start_year = date_start.getFullYear();
    let end_year = date_end.getFullYear();
    if(date_start.getMonth() === date_end.getMonth()){
        start_month = '';
    }
    if(date_start.getFullYear() === date_end.getFullYear()){
        start_year = '';
    }
    let format = `${start_day} ${start_month} ${start_year} - ${end_day} ${end_month} ${end_year}`;
    if((start_day === end_day) && (start_month === '') && (start_year === '')){
        format = `${end_day} ${end_month} ${end_year}`
    }
    if(!end){
        format = `${start_day} ${start_month} ${start_year}`;
    }

    return format;
}


export const checkDate = (bracket,index) =>{
    if(index < 2){
        if(new Date(bracket[1]) < new Date(bracket[0])){
            if(index === 1){
                return 'd-none'
            }
        }
    }
}