import React, { Component } from "react";
import ReactModal from "react-responsive-modal";
import { withRouter } from "react-router-dom";

class PopupRegis extends Component {

    state = {
        wave_id: false
    }

    mapSelection = (wave) => {
        return wave.filter(data => data.is_registered === true).map((data, key) =>
            <option key={key} value={data.id}>Tim Wave {data.id}</option>
        );
    }

    handleSelection = (e) => {
        this.setState({ wave_id: e.target.value });
    }

    handleUseTeam = (id) => {
        const { actionsMain, actionsRegis, history, main } = this.props;
        
        if (!id) {
            document.querySelector(".form-option").style.borderColor = "red";
        } else {
            actionsRegis.get_data_by_id(id);
            history.push(`/regis/${main.popup_reg_key}`);

            actionsMain.toggle_popup_reg(false, false);
        }
    }

    handleNewTeam = (id) => {
        const { actionsRegis, actionsMain, history } = this.props;

        actionsMain.toggle_nav();
        actionsRegis.reset_form();
        actionsRegis.put_data("err_dict", false);
        history.push(`/regis/${id}`);

        actionsMain.toggle_popup_reg(false, false);
    }

    render() {
        const { wave } = this.props.regis;
        const { popup_reg_is_open, popup_reg_key } = this.props.main;
        const { wave_id } = this.state;
        return (
            <ReactModal
                open={popup_reg_is_open}
                onClose={() => this.props.actionsMain.toggle_popup_reg(false)}
                center={true}
                closeOnOverlayClick={false}
                classNames={{
                    overlay: "custom-overlay",
                    modal: "custom-modal modal-regis",
                    closeButton: "custom-close"
                }}>
                <h1>Anda telah mengisi form sebelumnya</h1>
                <p>Apakah anda ingin menggunakan tim yang anda isi sebelumnya?</p>
                <div className="form-popup">
                    <select defaultValue={wave_id} className="form-option" onChange={this.handleSelection}>
                        <option value="">-- Pilih --</option>
                        { this.mapSelection(wave) }
                    </select>
                </div>
                <div className="popup-reg-btn">
                    <button className="btn-use-team" onClick={() => this.handleUseTeam(wave_id)}>Gunakan Tim</button>
                    <button className="btn-new-team" onClick={() => this.handleNewTeam(popup_reg_key)}>Tim Baru</button>
                </div>
            </ReactModal>
        );
    }

}

export default withRouter(PopupRegis);