import React, { Component } from 'react';
import {Link} from 'react-router-dom';
const api = process.env.REACT_APP_API_ENDPOINT;

class Mobile extends Component {
    constructor(props) {
        super(props);
        this.state = {onhover: false};
    }


    handleClick=()=>{
        this.setState({
            onhover: !this.state.onhover
        })
    }
    render() {
        const {actionsMain,actionsAuth,login} = this.props;
        const {onhover} = this.state;
		const {data} = this.props.main;
        
        return (
            <div className="nav_mobile">
                <div className="nav_top">
                    <img src={data?.img_logo ? data.img_logo : require('assets/img/Icon_Logo_ASL_.png')} alt="" className="img-fluid"/>
                    <p>ASL Qualifier</p>
                {login ?
                    <div className="acc">
                        <div className="acc_box">
                            <div className='nick'>
                                <span 
                                    onClick={()=>this.handleClick()} 
                                    onMouseOver={()=>this.handleClick()}
                                >{login.nickname.charAt(0).toUpperCase()}</span>
                            </div>
                            <ol className={`subnav ${onhover ? `active`:``}`}>
                                <li>
                                    <Link to="/regis" onClick={()=>actionsMain.toggle_nav()}>Edit</Link>
                                </li>
                                <li>
                                    <div onClick={()=>actionsAuth.logout()}>
                                    Logout
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                    :
                    <div>
                        <a href={`${api}oauth/login/`}>
                            Login
                        </a>
                    </div>
                }
                </div>
                <div className="nav_bottom">
                    <ul>
                        {this.props.renderNavbar()}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Mobile;
