const auth_state = {
	login: false
}

const auth = (state = auth_state, action) => {
    switch (action.type) {
		case "GET_LOGIN":
			return { 
				...state,
				login: action.data
			};
		default:
			return state;
	}
};

export default auth;