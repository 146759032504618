import React from 'react'
import ReactModal from 'react-responsive-modal'

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsMain from 'redux/actions/main';

class Popup extends React.Component {
	
    handleClose = () =>{
        const {actionsMain} = this.props;
		const {popup_key} = this.props.main;
		actionsMain.toggle_popup(false);
		if(popup_key === 'success'){
            window.location.href = '/regis';
		}
	}
    
	render(){
		const {popup_is_open,err_msg,popup_key, data} = this.props.main;
		return(
			<ReactModal 
                open={popup_is_open} 
                onClose={()=>this.handleClose()} 
                closeOnEsc={false}
                closeOnOverlayClick={false}
                center
                classNames={{ overlay: 'custom-overlay', modal:`custom-modal custom-${popup_key}`, closeButton:'custom-close'
                }}
            >
            	<div className={`popup_wrapper`}>
                    {popup_key === 'rules' ?
                        <embed src={data.link_rules} type="application/pdf" />
                        :
                        <React.Fragment>
                            <p>{data[err_msg] ?? err_msg}</p>
                            <div className="btn btn_ok" onClick={()=>this.handleClose()}>
                                <span>OK</span>
                            </div>
                        </React.Fragment>
                    }
            	</div>
            </ReactModal>
		)
	}
}

function mapStateToProps(state) {
    return {
    	main: state.main,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(actionsMain, dispatch),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Popup);