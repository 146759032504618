import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsMain from 'redux/actions/main';
import * as actionsRegis from 'redux/actions/regis';

// Component
import Wave from './comp/Wave'
import Form from './comp/form'

class Regis extends Component {

    render() {
    	const {wave} = this.props.match.params;

        return (
            <div className="regis">
            	<div className="regis_container">
            		<div className="regis_box">
            			{wave ?
            				<Form {...this.props}/>
            				:
	            			<Wave {...this.props}/>
            			}
            		</div>
            	</div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        main: state.main,
        regis: state.regis
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(actionsMain, dispatch),
        actionsRegis: bindActionCreators(actionsRegis, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Regis);
