import { combineReducers } from "redux";
import main from "./main";
import auth from "./auth";
import regis from "./regis";

export default combineReducers({
	main,
	auth,
	regis
});
