import React, { Component } from 'react';
import {dateFormat} from 'helper';
import {Link} from 'react-router-dom';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsMain from 'redux/actions/main';
import { checkDate, sendGAPageView } from '../../helper';

class Schedule extends Component {

    componentDidMount (){
        sendGAPageView();
    }

    versioning = () => {
		return Math.round(Math.random() * 100000000);
	}

    populateTempSchedule = () => {
        let list = [];
        for (let index = 1; index <= 4; index++) {
            list.push(
		    <div key={index} className="list" style={{display: "none"}}>
			    <Link to={`/regis/${index}`} onClick={()=>actionsMain.toggle_nav()} className="default_schedule">
				    <img src={`https://web.cdn.id.garenanow.com/webid/AOV/ASL%20S4/ASL%20Playoff/Schedule/day${index}.png?${this.versioning()}`} alt=""/>
			    </Link>
		    </div>
            )
        }
        return list;
    }
    
    render() {
        const {schedule} = this.props.regis;
        
        return (
            <div className="content_pages">
                <div className="content_pages_box schedule_content">
            	   <div className="title_content">
                        Schedule
                    </div>

                    {/* YOU CAN REMOVE THIS LATER */}
                    <div className="tempSchedule">
                        {this.populateTempSchedule()} 
                    </div>


                    {schedule && schedule.length > 0 ?
                    (schedule.map((data,x)=>
                        <div key={x} className="schedule_list">
                            {data.bracket.map((init,y)=>
                                <div key={y} className={`list ${checkDate(data.bracket,y)}`}>
                                    <div className="date">
                                        {dateFormat(init,true)}
                                    </div>
                                    <div className="list_box">
                                        <p>{`${data.name} Bracket ${y+1}`}</p>
                                        <a href={data.bracket_url[y]} className="btn_bracket" target="_blank" rel="noopener noreferrer" >
                                            See Bracket
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    ))
                    :''
                    }
                </div>
               <img src={require('assets/img/Ornate_DottedGradient.png')} alt="" className="gradient"/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        main: state.main,
        regis: state.regis
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(actionsMain, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Schedule);
