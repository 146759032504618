import React, { Component } from 'react';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";

// Component
import ViewportRestrict from 'helper/component/viewport';
import Loader from 'helper/component/loader';
import Navbar from 'partial/navbar';
import Header from 'partial/Header';
import Footer from 'partial/Footer';
import Popup from 'partial/Popup';
import PopupRegis from 'partial/PopupRegis';
import ScrollToTop from 'helper/component/scrollToTop';

// Pages
import Home from 'pages/home';
import Regis from 'pages/regis';
import About from 'pages/about';
import Schedule from 'pages/schedule';
import Teams from 'pages/teams';
import ErrorBoundary from 'ErrorBoundary';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsMain from 'redux/actions/main';
import * as actionsAuth from 'redux/actions/auth';
import * as actionsRegis from 'redux/actions/regis';

const NoMatch = () => {
	window.location.href = '/';
	return <></>;
}

class Main extends Component {

	componentDidMount(){
		this.props.actionsMain.get_data_static();
		this.props.actionsAuth.set_token();
	}

    render() {
    	const {loader} = this.props.main;
    	let pathname = window.location.pathname;
        return (
            <Router basename={process.env.REACT_APP_BASENAME}>
							<ScrollToTop />
							<div id="Main" className="main-panel new-classes">
								<ErrorBoundary>
									<ViewportRestrict display={true} type="landscape"/>
									<Loader loader={loader} />
									{(pathname.indexOf('/regis') > -1) ?
										<Header {...this.props}/>
										:
										<Navbar {...this.props}/> 
									}
									<div className={`content-container ${(pathname === '/') ? `nopadd`:``}`}>
										<Switch>
											<Route exact path="/" component={Home} />
											<Route exact path="/about" component={About} />
											<Route exact path="/schedule" component={Schedule} />
											<Route exact path="/teams" component={Teams} />
											<Route exact path="/regis" component={Regis} />
											<Route exact path="/regis/:wave" component={Regis} />
											<Route component={NoMatch} />
										</Switch>
									</div>
									<Footer {...this.props}/>
									<Popup/>
									<PopupRegis { ...this.props } />
								</ErrorBoundary>
							</div>
						</Router>
        );
    }
}

function mapStateToProps(state) {
	return {
		main: state.main,
		auth: state.auth,
		regis: state.regis
	}
}

function mapDispatchToProps(dispatch) {
	return {
		actionsMain: bindActionCreators(actionsMain, dispatch),
		actionsAuth: bindActionCreators(actionsAuth, dispatch),
		actionsRegis: bindActionCreators(actionsRegis, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);