import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {onhover: false};
    }

    handleClick=()=>{
        this.setState({
            onhover: !this.state.onhover
        })
    }

    render() {
        const {actionsAuth} = this.props;
        const {login} = this.props.auth;
        const {onhover} = this.state;
        var path = window.location.pathname;
        var pathname = path.split('/');
        const {actionsMain} = this.props;
        let link = `/`;
        if(pathname.length > 2){
            link = `/regis`
        }
        console.log(login)
        return (
            <div className="header">
                <div className="header_cont">
                	<Link to={link} className="back" onClick={()=>actionsMain.toggle_nav()}>
                        <img src={require('assets/img/Icon_Back.png')} alt=""/>
                    </Link>
                    <div className="header_text">
                         Formulir Pendaftaran ASL Qualifier
                    </div>
                    <div className="acc">
                        <div className="acc_box">
                            <div className='nick'>
                                <span 
                                    onClick={()=>this.handleClick()} 
                                    onMouseOver={()=>this.handleClick()}
                                >{login?.nickname?.charAt(0).toUpperCase()}</span>
                            </div>
                            <ol className={`subnav ${onhover ? `active`:``}`}>
                                <li>
                                    <Link to="/regis">Edit</Link>
                                </li>
                                <li>
                                    <div onClick={()=>actionsAuth.logout()}>
                                    Logout
                                    </div>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
