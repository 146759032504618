import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsMain from 'redux/actions/main';
import { sendGAPageView } from '../../helper';

class Teams extends Component {
    componentDidMount (){
        sendGAPageView();
    }

    renderTeams = (playoff = false) => {
        const {data} = this.props.main;
        
        if (!data) {
            return
        }

		let filtered = data.teams.filter((dt) => Boolean(dt.is_playoff) === playoff)
		return filtered.map((init, x) => 
            <div className="box_teams" key={x}>
                <div className="box_cont">
                    <div className="img_logo">
                        <img src={init.img_url} alt="" className="img-fluid"/>
                    </div>
                    <div className="name">
                        <span>{init.name}</span>
                    </div>
                </div>
            </div>
		)
    }

    render() {
        
        const { data } = this.props.main

        return (
            <div className="content_pages teams_pages">
                <div className="content_pages_box">
                    <div className="teams_content">
			<center>
				<h1>
					Team
				</h1>
			</center>
                        <div className="title_content">
                            {data?.title_is_playoff_no}
                        </div>
                        <div className="teams_list">
                            
                            {this.renderTeams()}
                        </div>

                        <br /> <br />
                        
                        <div className="title_content">
                            {data?.title_is_playoff}
                        </div>
                        <div className="teams_list">
                            {this.renderTeams(true)}
                        </div>
                    </div>
                </div>
                <img src={require('assets/img/Ornate_DottedGradient.png')} alt="" className="gradient"/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        main: state.main,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(actionsMain, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Teams);
