import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Teams extends Component {

	filterTeams = (data) => {
		const {playoff} = this.props;

		let filtered = data.filter((dt) => Boolean(dt.is_playoff) === playoff)
		return filtered.map((init, x) => 
			<div className={`box_teams ${playoff ? "playoff" : ""}`} key={x}>
				<div className="box_cont">
					<div className="img_logo">
						<img src={init.img_url} alt="" className="img-fluid"/>
					</div>
					<div className="name">
						<span>{init.name}</span>
					</div>
				</div>
			</div>
		)
	}

	render(){
		const {actionsMain, playoff} = this.props;
		const {data} = this.props.main;
		
		return(
			<React.Fragment>
				<div className="title">
					{playoff ? data?.title_is_playoff : data?.title_is_playoff_no}
					<Link to="/teams" onClick={()=>actionsMain.toggle_nav()}>Check all</Link>
				</div>
				<div className="teams_content">
					<div className="teams_list">
					{(data && data.teams && data.teams.length > 0) && this.filterTeams(data.teams)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

export default Teams;
