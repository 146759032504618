import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionsMain from 'redux/actions/main';
import { sendGAPageView } from '../../helper';

import AboutTab from 'pages/home/comp/About'
import Rules from 'pages/home/comp/Rules'

class About extends Component {
    
    componentDidMount (){
        sendGAPageView();
    }

    render() {
        const { data } = this.props.main

        return (
            <div className="about_content">
                <div className="about_box">
                    <div className="title_content">
                        About {data?.title_is_playoff}
                    </div>
            	   <AboutTab {...this.props}/>
                </div>
                <div className="rules_about">
                    <Rules {...this.props}/>
                </div>
                <img src={require('assets/img/Ornate_DottedGradient.png')} alt="" className="gradient"/>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        main: state.main,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actionsMain: bindActionCreators(actionsMain, dispatch),
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(About);
