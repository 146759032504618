import React, { Component } from 'react';
import {dateFormat} from 'helper';
import { checkDate } from '../../../../helper';

class Bracket extends Component {
	render(){
		const {bracket} = this.props;
		const {actionsRegis,regis,err} = this.props;

		return(
			<div className="bracket">
				{(err(`bracket1`) || err(`bracket2`) || err(`bracket3`) || err(`bracket1`))?
					<div className="err_msg">
						Pilih salah satu bracket
					</div>:''
				}
				{(bracket && bracket.length > 0) ?
					bracket.map((data,x)=>
						<div key={x} className={`bracket_box ${checkDate(bracket,x)}`}>
							<div className="bracket_detail">
								<div className="name">Bracket {x+1}</div>
								<div className="dates">{dateFormat(data)}</div>
							</div>
							<div className="bracket_tick" onClick={()=>actionsRegis.handle_tick(x+1)}>
							{Boolean(regis[`bracket${x+1}`]) ?
								<img src={require('assets/img/tick.png')} alt=""/>
								: ''
							}
							</div>
						</div>
					)
					:
					''
				}
			</div>
		);
	}
}

export default Bracket;