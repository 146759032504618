import React, { Component } from 'react';

class Data extends Component {
	render(){
		const {regis,actionsRegis,actionsMain,err} = this.props;
		return(
			<div className="fill_form">
				<div className="form_group">
					<div className="label">
						Nama Tim
					</div>
					<div className={`input ${err(`team_name`) ? `err`:``}`}>
						<input type="text" name="team_name" 
							placeholder="Jawaban Anda" 
							value={regis["team_name"]} 
							onChange={(e)=>actionsRegis.handleChange(e)} 
							onFocus={()=>actionsMain.onfocus(true)} 
							onBlur={()=>actionsMain.onfocus(false)}
						/>
					</div>
					{err(`team_name`)?
						<div className="err_msg">{err(`team_name`)}</div>:''
					}
				</div>
				<div className="form_group">
					<div className="label">Alamat Email</div>
					<div className={`input ${err(`team_email`) ? `err`:``}`}>
						<input type="text" name="team_email" 
							placeholder="Jawaban Anda"
							value={regis["team_email"]} 
							onChange={(e)=>actionsRegis.handleChange(e)} 
							onFocus={()=>actionsMain.onfocus(true)} 
							onBlur={()=>actionsMain.onfocus(false)}
						/>
					</div>
					{err(`team_email`)?
						<div className="err_msg">{err(`team_email`)}</div>:''
					}
				</div>
				<div className="form_group">
					<div className="label">Asal Kota</div>
					<div className={`input ${err(`team_city`) ? `err`:``}`}>
						<input type="text" name="team_city" 
							placeholder="Jawaban Anda"
							value={regis["team_city"]}
							onChange={(e)=>actionsRegis.handleChange(e)} 
							onFocus={()=>actionsMain.onfocus(true)} 
							onBlur={()=>actionsMain.onfocus(false)}
						/>
					</div>
					{err(`team_city`)?
						<div className="err_msg">{err(`team_city`)}</div>:''
					}
				</div>
			</div>
		);
	}
}

export default Data;