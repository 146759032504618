import React, { Component } from 'react';

class Teams extends Component {
	render(){
		const {type,name,regis,actionsRegis,actionsMain,err} = this.props;
		return(
			<div className="fill_form">
				<div className="label title">
				{type === 'Kapten'?
					`Kapten Team`: 
					`${type === 'Cadangan' ? `Anggota Cadangan (jika ada)`: type}`
				}
				</div>
				<div className="form_group">
					<div className="label">
						Nama Lengkap {type}
					</div>
					<div className={`input ${err(`${name}_name`) ? `err`:``}`}>
						<input type="text" name={`${name}_name`} 
							placeholder="Jawaban Anda"
							value={regis[`${name}_name`]} 
							onChange={(e)=>actionsRegis.handleChange(e)} 
							onFocus={()=>actionsMain.onfocus(true)} 
							onBlur={()=>actionsMain.onfocus(false)}
						/>
					</div>
					{err(`${name}_name`)?
						<div className="err_msg">{err(`${name}_name`)}</div>:''
					}
				</div>
				{(name === 'member1' || name === 'member2') ?
					<div className="form_group">
						<div className="label">
							Nomor Whatsapp {type}
						</div>
						<div className={`input ${err(`${name}_phone`) ? `err`:``}`}>
							<input type="text" name={`${name}_phone`} 
								placeholder="Jawaban Anda"
								value={regis[`${name}_phone`]} 
								onChange={(e)=>actionsRegis.handleChange(e)} 
								onFocus={()=>actionsMain.onfocus(true)} 
								onBlur={()=>actionsMain.onfocus(false)}
							/>
						</div>
						{err(`${name}_phone`)?
							<div className="err_msg">{err(`${name}_phone`)}</div>:''
						}
					</div>
					:
					''
				}
				<div className="form_group">
					<div className="label">
						Nick in game {type}
					</div>
					<div className={`input ${err(`${name}_nick`) ? `err`:``}`}>
						<input type="text" name={`${name}_nick`} 
							placeholder="Jawaban Anda"
							value={regis[`${name}_nick`]}
							onChange={(e)=>actionsRegis.handleChange(e)} 
							onFocus={()=>actionsMain.onfocus(true)} 
							onBlur={()=>actionsMain.onfocus(false)}
						/>
					</div>
					{err(`${name}_nick`)?
						<div className="err_msg">{err(`${name}_nick`)}</div>:''
					}
				</div>
				<div className="form_group">
					<div className="label">
						Open ID {type} (Open ID Terletak pada Pengaturan di sebelah Nama Pemain)
					</div>
					<div className={`input ${err(`${name}_open_id`) ? `err`:``}`}>
						<input type="text" name={`${name}_open_id`} 
							placeholder="Jawaban Anda"
							value={regis[`${name}_open_id`]} 
							onChange={(e)=>actionsRegis.handleChange(e)} 
							onFocus={()=>actionsMain.onfocus(true)} 
							onBlur={()=>actionsMain.onfocus(false)}
						/>
					</div>
					{err(`${name}_open_id`)?
						<div className="err_msg">{err(`${name}_open_id`)}</div>:''
					}
				</div>
			</div>
		);
	}
}

export default Teams;