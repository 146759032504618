import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
// import * as serviceWorker from './serviceWorker'; // UNCOMMENT FOR PWA
import { initGA } from './helper';

// COMPONENT & OTHER
import 'assets/scss/index.scss';
import Main from 'base/Main';

// REDUX
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import rootReducer from 'redux/reducers';

initGA();

// SET REDUX STORE
const store = createStore(
    rootReducer,
    applyMiddleware(thunk)
)

// SET DEFAULT AXIOS
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

if (process.env.REACT_APP_USE_TOKEN === 'true') {
    var token = window.localStorage.getItem('token');
    axios.defaults.headers[process.env.REACT_APP_TOKEN_HEADER_NAME] = token;
}

ReactDOM.render(
    <Provider store={store}>
        <Main />
    </Provider>,
    document.getElementById('root')
);


// serviceWorker.unregister(); // UNCOMMENT TO REMOVE PWA || COMMENT THE REGISTER
// serviceWorker.register(); // UNCOMMENT FOR PWA