import axios from 'axios';

const cdn = axios.create({
    baseURL: `https://cdngarenanow-a.akamaihd.net/devid/${process.env.REACT_APP_LOCALIZE_NAME}/`,
    // baseURL: `https://cdngarenanow-a.akamaihd.net/webid/Localize/global_aov_asl4/`,
    withCredentials: false,
    transformRequest: [(data, headers) => {
        delete headers[process.env.REACT_APP_TOKEN_HEADER_NAME]
        return data
    }]
})

export const toggle_nav = () => ({
    type: 'TOGGLE_NAV'
})

export const change_tab = (data) =>({
    type: 'CHANGE_TAB',
    data
})

export const toggle_loader = (data) => ({
    type: 'TOGGLE_LOADER',
    data
})

export const put_data = (key, data) => ({
    type: "PUT_DATA",
    key,
    data
})

export const get_translate = (lang) => {
    return dispatch => {
        cdn.get(`${lang}.json?${Math.random() * 10000}`)
        .then(resp => {
            dispatch(put_data('lang_json', resp.data))
        })
        .catch(err => {

        })
    }
}

export const get_data_static = () =>{
    return dispatch =>{
        dispatch(toggle_loader(true))    
        cdn.get(`data.json?${Math.random() * 10000}`)
        .then(resp => {
            const {data} = resp.data
            dispatch(put_data('data', data))
            let target = document.documentElement;
            // eslint-disable-next-line
            target?.style.setProperty('--img_bg_desktop', `url(${data.img_bg_desktop})`);
            // eslint-disable-next-line
            target?.style.setProperty('--img_bg_mobile', `url(${data.img_bg_mobile})`);
        })
        .catch(err => {
            if(err.response){
                // dispatch(main.catch_error(err.response.data.msg))
            }else{
                dispatch(catch_error())
            }
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

export const toggle_popup = (data,key) => ({
    type: 'TOGGLE_POPUP',
    data,
    key
})

export const toggle_popup_reg = (data, key) => ({
    type: 'TOGGLE_POPUP_REG',
    data,
    key
})

export const catch_error = (msg) => {
    return (dispatch) =>{
        let text = msg;
        if(!msg){
            text = 'Please refresh your browser!';
        }
        dispatch(put_data('err_msg',text));
        dispatch(toggle_loader(false));
        dispatch(toggle_popup(true,'err'));
    }
}

export const onfocus = (state) => {
    return dispatch =>{
        if(state){
            document.getElementById("w_pop").classList.add('dnone');
        }else{
            document.getElementById("w_pop").classList.remove('dnone');
        }
    }
}
