import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { sendGAPageView } from '../../../../helper';

import Bracket from './Bracket';
import Data from './data/Data';
import Teams from './data/Teams';
import Wave from '../../comp/Wave'

class Form extends Component {
	
	componentDidMount(){
		sendGAPageView();
		const {wave} = this.props.match.params;
		this.getData(wave)
	}

	check_open = (id) =>{
		const {wave} = this.props.regis;
	    let open = true;
	    if(wave && wave.length > 0){
	        for(var i=0;i<wave.length;i++){
	            if(parseInt(id) === parseInt(wave[i].id)){
	                if(!wave[i].is_editable){
	                    open = false;
	                }
	            }
	        }
	    }
	    return open
	}

	getData = (wave) =>{
		const {actionsRegis,history} = this.props;
		actionsRegis.get_data_by_id(wave,history);
	}

	mapErr = (key) =>{
		const {err_dict} = this.props.regis;
		if(err_dict.length > 0){
	        for(var i=0;i< err_dict.length;i++){
	            if(err_dict[i][key]){
	            	return err_dict[i][key]
	            }
	        }
	    }else{
	        return ''
	    }
	}

	mapBracket = (id) =>{
		const {wave} = this.props.regis;
		var data = false;
		if(wave && wave.length > 0){
			for(var i=0;i<wave.length;i++){
				if(parseInt(id) === parseInt(wave[i].id)){
					data = wave[i]
				}
			}
		}
		return data;
	}

	mapAnggota = () =>{
		let list = [];
		for(var i=2;i<=5;i++){
			list.push(
				<Teams key={i} {...this.props} type={`Anggota ${i}`} name={`member${i}`} err={this.mapErr}/>
			)
		}
		return list;
	}

	handleSubmit = (id) =>{
		const {actionsRegis} = this.props;
		var edit = false;
		actionsRegis.put_data('wave_id',id);
		if(this.mapBracket(id) && this.mapBracket(id).is_registered){
			edit = true;
		}
		this.props.actionsRegis.post_data(edit,id);
	}

	render(){
		const {wave} = this.props.match.params;
		if(this.check_open(wave)){
			return(
			<React.Fragment>
				<div className="form">
					<div className="regis_title">
						Pilih Bracket Anda
						<span>Anda bisa memilih semua bracket</span>
					</div>
					<Bracket {...this.props} bracket={this.mapBracket(wave).bracket} err={this.mapErr}/>
					<div className="regis_title">
						Isi Data Tim
					</div>
					<Data {...this.props} err={this.mapErr}/>
					<Teams {...this.props} type="Kapten" name="member1" err={this.mapErr}/>
					{this.mapAnggota()}
					<Teams {...this.props} type="Cadangan" name="member6" err={this.mapErr}/>
				</div>
				<div className="cont_btn">
					<div className="btn_submit" onClick={()=>this.handleSubmit(wave)}>
						Submit
					</div>
					<Link to="/regis" className="btn_cancel">
						Cancel
					</Link>
				</div>
			</React.Fragment>			
			);
		}else{
			/*return(<Redirect to="/regis"/>)*/
			return(<div>
				<Wave {...this.props}/>
				</div>);
		}

	}
}

export default Form;
