import axios from 'axios';
import {toggle_popup,catch_error,toggle_loader, put_data as main_put_data} from './main'
import {form} from '../reducers/regis'

export const put_data = (key, data) => ({
    type: "PUT_DATA",
    key,
    data
})

export const reset_form = ()=>({
    type: 'RESET_FORM'
})

export const get_data_update = (data) =>({
    type: 'GET_DATA_UPDATE',
    data
})

export const handle_tick = (id) =>({
    type: 'HANDLE_TICK',
    id
})

export const handleChange = (e) =>{
    return dispatch =>{
        const {name,value}= e.currentTarget;
        dispatch(put_data(name,value));      
    }
}

export const get_schedule = () => {
    return dispatch => {
        dispatch(toggle_loader(true))
        axios.get('api/schedule/')
        .then((resp) => {
            dispatch(put_data('schedule',resp.data.wave))
        })
        .catch((err) => {
            if(err.response){
                dispatch(catch_error(err.response.data.message))
            }else{
                dispatch(catch_error())
            }
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

export const get_wave = () => {
    return dispatch => {
        dispatch(toggle_loader(true))
        axios.get('api/bracket/')
        .then((resp) => {
            dispatch(put_data('wave',resp.data.wave))
        })
        .catch((err) => {
            if(err.response){
                dispatch(catch_error(err.response.data.message))
            }else{
                dispatch(catch_error())
            }
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

export const get_data_by_id = (id,history) => {
    return (dispatch,getState) => {
        dispatch(toggle_loader(true))
        axios.get(`api/bracket/update/?id=${id}`)
        .then((resp) => {
            dispatch(get_data_update(resp.data))
            if(history){
                history.push(`/regis/${id}`)
            }
        })
        .catch((err) => {
            if(err.response){
                dispatch(catch_error(err.response.data.message))
            }else{
                dispatch(catch_error())
            }
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}

const initForm = (regis)=>{
    let formData = new FormData();
    Object.keys(form).forEach(key=>{
        formData.append(key,regis[key])
    })
    return formData;
}

export const post_data = (edit,id) => {
    return (dispatch,getState) => {
        dispatch(toggle_loader(true))
        var data = initForm(getState().regis)
        var url = `api/bracket/`;
        if(edit){
            url = `api/bracket/update/`;
        }
        axios.post(url,data)
        .then((resp) => {
            dispatch(put_data('err_dict',false))        
            let msg = `Selamat tim kamu sudah berhasil terdaftar di Wave ${id}`;
            if(edit){
                msg = 'Berhasil update data!';
            }
            dispatch(main_put_data('err_msg',msg));
            dispatch(toggle_loader(false));
            dispatch(toggle_popup(true,'success'));
        })
        .catch((err) => {
            if(err.response){
                if(err.response.data.err_dict){
                    dispatch(put_data('err_dict',err.response.data.err_dict))
                    window.scrollTo(0,0);
                    dispatch(catch_error("Mohon maaf data yang kamu masukan ada kesalahan, tolong diperiksa kembali"))
                }else{
                    if(err.response.data.msg){
                        dispatch(catch_error(err.response.data.msg))
                    }else{
                        dispatch(catch_error("Mohon maaf data yang kamu masukan ada kesalahan, tolong diperiksa kembali"))
                    }
                }
            }else{
                dispatch(catch_error())
            }
        })
        .then(()=>{
            dispatch(toggle_loader(false))
        })
    }
}
