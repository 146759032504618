import React, { Component } from 'react';
import {Link} from 'react-router-dom';
const api = process.env.REACT_APP_API_ENDPOINT;
class Desktop extends Component {
    constructor(props) {
        super(props);
        this.state = {onhover: false};
    }


    handleClick=()=>{
        this.setState({
            onhover: !this.state.onhover
        })
    }

    render() {
		const {actionsMain,actionsAuth,login} = this.props;
        const {onhover} = this.state;
		const {data} = this.props.main;
        return (
            <div className="nav_desktop">
            	<ul>
            		<li className="logo">
            			<Link to="">
            				<img src={data?.img_logo ? data.img_logo : require('assets/img/Icon_Logo_ASL_.png')} alt="" className="img-fluid"/>
            			</Link>
            		</li>
            		{this.props.renderNavbar()}
                    {login ?
                		<li className="acc">
                			<div className="acc_box">
                                <div className='nick'>
                    				<span 
                                        onClick={()=>this.handleClick()} 
                                        onMouseOver={()=>this.handleClick()}
                                    >{login.nickname.charAt(0).toUpperCase()}</span>
                    			</div>
                                <ol className={`subnav ${onhover ? `active`:``}`}>
                                    <li>
                                        <Link to="/regis" onClick={()=>actionsMain.toggle_nav()}>Edit</Link>
                                    </li>
                                    <li>
                                        <div onClick={()=>actionsAuth.logout()}>
                                        Logout
                                        </div>
                                    </li>
                                </ol>
                            </div>
                		</li>
                        :
                        <li>
                            <a href={`${api}oauth/login/`}>
                                Login
                            </a>
                        </li>
                    }
            	</ul>
            </div>
        );
    }
}

export default Desktop;